@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  min-width: 320px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
@layer components {
  .btn {
    @apply px-3 py-1 bg-color2 rounded-md hover:bg-color1 hover:text-color3 text-color1 duration-500 dark:text-colorD3 dark:bg-colorD2 dark:hover:bg-colorD4;
  }
  .menu-container {
    @apply flex lg:static lg:translate-x-0 lg:bg-color5 fixed duration-500 bg-color6 top-0 right-0 w-full h-full justify-end z-10 lg:dark:bg-colorD1;
  }
  .menu-content {
    @apply flex lg:gap-6 lg:flex-row lg:bg-color5 lg:px-0 lg:py-0 gap-3 flex-col bg-color3 px-5 py-5 top-1 right-1 rounded-md dark:bg-colorD1;
  }
}
.swiper-button-prev::after,
.swiper-button-next::after {
  color: #026670;
  display: none;
}
@media (min-width: 768px) {
  .swiper-button-prev::after,
  .swiper-button-next::after {
    display: block;
  }
}
.menu {
  transform: translate(100%, 0);
}
.menu-active {
  transform: translate(0, 0);
}
.menu-icon {
  z-index: 11;
  display: block;
  position: relative;
  width: 27px;
  height: 16px;
  cursor: pointer;
}
.menu-icon span,
.menu-icon::before,
.menu-icon::after {
  left: 0;
  position: absolute;
  height: 10%;
  width: 100%;
  transition: all 0.3s ease 0s;
  background-color: #026670;
}
.menu-icon::before,
.menu-icon::after {
  content: "";
}
.menu-icon::before {
  top: 0;
}
.menu-icon::after {
  bottom: 0;
}
.menu-icon span {
  top: 50%;
  transform: scale(1) translate(0px, -50%);
}

.menu-icon._active span {
  transform: scale(0) translate(0px, -50%);
}
.menu-icon._active::before {
  top: 50%;
  transform: rotate(-45deg) translate(0px, -50%);
}
.menu-icon._active::after {
  bottom: 50%;
  transform: rotate(45deg) translate(0px, 50%);
}
